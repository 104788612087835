import React from "react";
import { client } from "./src/apollo/client";
import { ApolloProvider } from "@apollo/client";
import GlobalFonts from "./src/fonts/Fonts";
import { OnboardingProvider } from "./src/components/PageSegments/onboarding/OnboardingContext";
import { RemoteConfigProvider } from "./src/providers/RemoteConfigProvider";
import { LocalPriceProvider } from "./src/providers/LocalPriceProvider";
import { isDevEnvironment } from "./src/util/misc";
import { saveUTMMixpanelProperties } from "./src/services/mixpanel/mixpanel";

export const wrapRootElement = ({ element }) => {
  isDevEnvironment();
  saveUTMMixpanelProperties();
  return (
    <>
      <GlobalFonts />
      <ApolloProvider client={client}>
        <RemoteConfigProvider>
          <OnboardingProvider>
            <LocalPriceProvider>
              {element}
            </LocalPriceProvider>
          </OnboardingProvider>
        </RemoteConfigProvider>
      </ApolloProvider>
    </>
  );
};
