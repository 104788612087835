import { useState, useEffect, useContext } from "react";
import {
  getRemoteConfigBoolean,
  getRemoteConfigString,
  REMOTE_CONFIG_BOOLEAN_KEYS,
  REMOTE_CONFIG_STRING_KEYS,
} from "../services/firebase-remote-config";
import { RemoteConfigContext } from "../providers/RemoteConfigProvider";

const useRemoteConfig = <T extends REMOTE_CONFIG_STRING_KEYS | REMOTE_CONFIG_BOOLEAN_KEYS>(key: T) => {
  type ReturnType = T extends REMOTE_CONFIG_STRING_KEYS ? string : boolean;

  const config = useContext(RemoteConfigContext);
  const [value, setValue] = useState<ReturnType | null>(null);
  const [useDefault, setUseDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (config.initialized) {
      if (key in REMOTE_CONFIG_STRING_KEYS) {
        setValue(getRemoteConfigString(key as REMOTE_CONFIG_STRING_KEYS) as ReturnType);
      } else {
        setValue(getRemoteConfigBoolean(key as REMOTE_CONFIG_BOOLEAN_KEYS) as ReturnType);
      }
    } else if (config.failedToInitialize) {
      setUseDefault(true);
    }

    setIsLoading(false);
  }, [key, config]);

  return [value, useDefault, isLoading];
};

export default useRemoteConfig;
