import mixpanel from "mixpanel-browser";
import {
  capitalizeFirstLetter,
  getWebFlow,
  isDevEnvironment,
  isPreviewEnvironment,
} from "../../util/misc";

import { MIXPANEL_API_KEY } from "../../util/variables";

mixpanel.init(MIXPANEL_API_KEY, { debug: true, ignore_dnt: true });
const unique_id = mixpanel.get_distinct_id();

mixpanel.identify(unique_id);

export const getMixpanelDistinctId = () => {
  return unique_id;
};

export const mixpanelIdentify = (id: string) => {
  mixpanel.identify(id);
};

export const trackEvent = (event: string, properties?: object, webFlow?: string) => {
  const flow = getWebFlow();
  const uploadProperties = {
    ...properties,
    platform: "web",
    "Web Flow": webFlow ?? flow,
    "Screen URL": typeof window === "undefined" ? undefined : window.location.href,
    "Dev User": isDevEnvironment() || isPreviewEnvironment(),
  };

  if (isDevEnvironment() || isPreviewEnvironment()) {
    setMixpanelProperties({
      isDevUser: true,
    });
  }

  mixpanel.track(event, uploadProperties);
};

export const setMixpanelAlias = (alias: string) => {
  mixpanel.alias(unique_id, alias);
};

export const setMixpanelProperties = (properties: object, setOnce?: boolean) => {
  return setOnce ? mixpanel.people.set_once(properties) : mixpanel.people.set(properties);
};

const formatUTMKey = (key: string) => {
  return key.replace("utm", "UTM").replace("_", " ");
};

export const saveUTMMixpanelProperties = () => {
  if (typeof window === "undefined") {
    return;
  }

  const allowedUTMKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_channel"];

  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmCampaign = urlParams.get("utm_campaign");
  const utmContent = urlParams.get("utm_content");
  const utmChannel = urlParams.get("utm_channel");

  const data: any = {};
  allowedUTMKeys.forEach(key => {
    if (urlParams.has(key)) {
      const value = urlParams.get(key);
      data[formatUTMKey(key)] = value;
    }
  });

  if (Object.keys(data).length === 0) {
    // no UTM data found
    return;
  }

  setMixpanelProperties(
    {
      "UTM source": utmSource,
      "UTM medium": utmMedium,
      "UTM campaign": utmCampaign,
      "UTM content": utmContent,
      "UTM channel": utmChannel,
    },
    true,
  );

  localStorage.setItem("UTM", JSON.stringify(data));
};

export const kaagLandingViewed = () => {
  trackEvent("Web_Kaag_Landing_Page");
  mixpanel.people.set({
    $LandingPage: "John Kaag Landing Page",
  });
};

export const dynamicLandingViewed = (bookName: string) => {
  trackEvent("Web_Dynamic_Landing_Page");
  mixpanel.people.set({
    $LandingPage: `${bookName} Landing Page`,
  });
};

export const timelineTrialScreen_Start = () => {
  trackEvent("Web_TimelineTrialScreen_Start");
};

export const timelineTrialScreen_StartTrial = () => {
  trackEvent("Web_TimelineTrialScreen_StartTrial");
};

export const timelineCouponScreen_Start = () => {
  trackEvent("Web_TimelineCouponScreen_Start");
};

export const timelineTrialScreen_ViewAllPlans = () => {
  trackEvent("Web_TimelineTrialScreen_ViewAllPlans");
};

export const ViewAllPlansScreen_MonthlySelect = () => {
  trackEvent("Web_ViewAllPlansScreen_MonthlySelect");
};

export const ViewAllPlansScreen_YearlySelect = () => {
  trackEvent("Web_ViewAllPlansScreen_YearlySelect");
};

export const Subscribe_ChangePlan = () => {
  trackEvent("Web_Subscribe_ChangePlan");
};

export const Subscribe_Device_iPhone = () => {
  trackEvent("Web_Subscribe_Device_iPhone");
};

export const Subscribe_Device_Android = () => {
  trackEvent("Web_Subscribe_Device_Android");
};

export const Subscribe_Device_Other = () => {
  trackEvent("Web_Subscribe_Device_Other");
};

export const Subscribe_Device_NotifyFormLink = () => {
  trackEvent("Web_Subscribe_Device_NotifyFormLink");
};

export const Subscribe_Account_EmailFocus = () => {
  trackEvent("Web_Subscribe_Account_EmailFocus");
};

export const Subscribe_Account_EmailError = () => {
  trackEvent("Web_Subscribe_Account_EmailError");
};

export const Subscribe_Account_PasswordFocus = () => {
  trackEvent("Web_Subscribe_Account_PasswordFocus");
};

export const Subscribe_Account_PasswordError = () => {
  trackEvent("Web_Subscribe_Account_PasswordError");
};

export const Subscribe_Account_AccountCreationSuccess = async (
  email: string,
  firebaseID: string,
) => {
  return new Promise((resolve, reject) => {
    try {
      trackEvent("Web_Subscribe_Account_AccountCreationSuccess");
      mixpanel.identify(unique_id);
      mixpanel.alias(unique_id, email);
      mixpanel.alias(firebaseID, unique_id);
      mixpanel.people.set({
        $email: email,
        $name: email,
        $onesignal_user_id: firebaseID,
        Subscriber: true,
        "Subscription Platform": "Web",
        "Subscription Service": "Stripe",
      });
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const web_store_onboarding_data_failure = () => {
  trackEvent(`Web_StoreOnboardingData_Failure`);
};

export const Subscribe_GoToPayment = () => {
  trackEvent("Web_Subscribe_GoToPayment");
  // mixpanel.alias(unique_id, email);
};

export const subscription_success = ({ subscription_type }: { subscription_type: string }) => {
  const type = capitalizeFirstLetter(subscription_type);
  trackEvent(`Web_Subscribe_${type}Success`);
  mixpanel.people.set({
    Purchases_Store: "stripe",
  });
  // mixpanel.identify(uid);
};

export const subscription_failed = ({ subscription_type }: { subscription_type: string }) => {
  const type = capitalizeFirstLetter(subscription_type);
  trackEvent(`Web_Subscribe_${type}Fail`);
};

export const group_signup_success = (gid: string) => {
  trackEvent("Web_Group_Signup");
  mixpanel.people.set({
    $GroupId: gid,
  });
};

export const landed_from_promo = (promo: string) => {
  trackEvent("Landed_From_Promo");
  mixpanel.people.set({
    PromoName: promo,
  });
};

export const promo_signup_success = (promo: string) => {
  trackEvent("Promo_Signup_Success");
  mixpanel.people.set({
    $PromoName: promo,
  });
};

export const promo_purchase_success = () => {
  trackEvent("Promo_Purchase_Success");
};

// ONBOARDING QUESTIONS EVENTS
export const onboarding_questions_start = () => {
  trackEvent("Web_Onboarding_Questions_Start");
};

export const onboarding_questions_select_answer = () => {
  trackEvent("Web_Onboarding_Questions_SelectAnswer");
};

export const onboarding_questions_deselect_answer = () => {
  trackEvent("Web_Onboarding_Questions_DeSelectAnswer");
};

export const onboarding_questions_submit_answer = () => {
  trackEvent("Web_Onboarding_Questions_SubmitAnswer");
};

export const onboarding_questions_final_report = () => {
  trackEvent("Web_Onboarding_Questions_FinalReport");
};

// SSO EVENTS
export const track_apple_createAccount_start = () => {
  trackEvent("CreateAccountView_SignInApple_Start", {
    platform: "web",
  });
};

export const track_apple_login_start = (properties?: object) => {
  trackEvent("“LoginView_SignInApple_Start", {
    platform: "web",
    ...properties,
  });
};

export const track_apple_signin_success = () => {
  trackEvent("SignInApple_Success”", {
    platform: "web",
  });
};

export const track_apple_signin_fail = () => {
  trackEvent("SignInApple_Fail”", {
    platform: "web",
  });
};

export const track_google_createAccount_start = () => {
  trackEvent("CreateAccountView_SignInGoogle_Start", {
    platform: "web",
  });
};

export const track_google_login_start = (properties?: object) => {
  trackEvent("“LoginView_SignInGoogle_Start", {
    platform: "web",
    ...properties,
  });
};

export const track_google_signin_success = () => {
  trackEvent("SignInGoogle_Success”", {
    platform: "web",
  });
};

export const track_google_signin_fail = () => {
  trackEvent("SignInGoogle_Fail”", {
    platform: "web",
  });
};

// SSO EVENTS END

export const track_email_login_start = (properties?: object) => {
  trackEvent("WebEmailLogin_Start", {
    platform: "web",
    ...properties,
  });
};

export const track_email_login_success = (properties?: object) => {
  trackEvent("WebEmailLogin_Success", {
    platform: "web",
    ...properties,
  });
};

export const track_email_login_fail = (properties?: object) => {
  trackEvent("WebEmailLogin_Fail", {
    platform: "web",
    ...properties,
  });
};

export const track_email_login_confirm_start = (properties?: object) => {
  trackEvent("WebEmailLogin_Confirm_Start", {
    platform: "web",
    ...properties,
  });
};

export const track_email_login_confirm_success = (properties?: object) => {
  trackEvent("WebEmailLogin_Confirm_Success", {
    platform: "web",
    ...properties,
  });
};

export const track_email_login_confirm_fail = (properties?: object) => {
  trackEvent("WebEmailLogin_Confirm_Fail", {
    platform: "web",
    ...properties,
  });
};

export const track_homepage_visit = () => {
  trackEvent("WebHomepageVisit", {
    "UTM Source": "homepage",
  });
};

export const track_hompage_get_started_click = () => {
  trackEvent(
    "web_homepage_click",
    {
      "UTM Source": "homepage",
    },
    "get started",
  );
};

export const track_nav_clicked = (clicked: string) => {
  trackEvent("WebHeaderClick", {
    clicked,
  });
};

export const track_account_manage_click = (clicked: "EmailEdit" | "PasswordEdit" | "Manage") => {
  trackEvent("WebAccountClick", {
    clicked,
  });
};

export const track_account_view = () => {
  trackEvent("WebAccountView");
};
