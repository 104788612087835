import { createGlobalStyle } from "styled-components";

import BoogyBrutBook from "./BoogyBrut/Boogy_Brut_WEB-Book.woff2";
import BoogyBrutRegular from "./BoogyBrut/Boogy_Brut_WEB-Regular.woff2";
import BoogyBrutLight from "./BoogyBrut/Boogy_Brut_WEB-Light.woff2";

import TheinhardtLight from "./Theinhardt/Theinhardt-Light.woff2";
import TheinhardtRegular from "./Theinhardt/Theinhardt-Regular.woff2";
import TheinhardtRegularItalic from "./Theinhardt/Theinhardt-Italic.otf";
import TheinhardtMedium from "./Theinhardt/Theinhardt-Medium.woff2";
import TheinhardtMediumItalic from "./Theinhardt/Theinhardt-Medium-Italic.woff2";
import TheinhardtBold from "./Theinhardt/Theinhardt-Bold.otf";

export default createGlobalStyle`
    // Boogy Brut

    @font-face {
        font-family: "boogy brut";
        src: url(${BoogyBrutBook});
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: "boogy brut";
        src: url(${BoogyBrutLight});
        font-style: normal;
        font-weight: 350;
    }

    @font-face {
        font-family: "boogy brut";
        src: url(${BoogyBrutRegular});
        font-style: normal;
        font-weight: 450;
    }

    // Theinhardt

    @font-face {
        font-family: "theinhardt";
        src: url(${TheinhardtLight});
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: "theinhardt";
        src: url(${TheinhardtRegular});
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: "theinhardt";
        src: url(${TheinhardtRegularItalic});
        font-style: italic;
        font-weight: 400;
    }
    @font-face {
        font-family: "theinhardt";
        src: url(${TheinhardtMedium});
        font-style: normal;
        font-weight: 500;
    }
    @font-face {
        font-family: "theinhardt";
        src: url(${TheinhardtMediumItalic});
        font-style: italic;
        font-weight: 500;
    }
    @font-face {
        font-family: "theinhardt";
        src: url(${TheinhardtBold});
        font-style: normal;
        font-weight: 700;
    }
`;
