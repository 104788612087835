import * as Yup from "yup";

export const emailAssertion = Yup.string()
  .email("Email must be a valid email")
  .required("Email must be valid");
export const passwordAssertion = Yup.string()
  .min(8, "Password must contain at least 8 characters")
  .required("Password is required ");
export const confirmPasswordAssertion = Yup.string()
  .min(8, "Password is too short - should be 8 chars minimum.")
  .required("Password is required ")
  .oneOf([Yup.ref("password"), null], "Passwords must match");

export const newUserSchema = Yup.object().shape({
  email: emailAssertion,
  password: passwordAssertion,
});

export const existingUserSchema = Yup.object().shape({
  email: emailAssertion,
  password: Yup.string().required("Password is required "),
});
