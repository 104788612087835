export enum GTM_CUSTOM_EVENT {
  COMPLETE_REGISTRATION = "GTM Complete Registrations",
  PURCHASE = "GTM Purchases",
  WEB_ACQ_PREPAYMENT_VIEW = "WebAcqPrepaymentView",
  WEB_ACQ_PREPAYMENT_SUCCESS = "WebAcqPrepaymentSuccess",
}

export type PriceDataT = {
  annual_price: string;
  discounted_annual_price: string;
  price: string;
  discounted_price: string;
  price_id: string;
  price_id_no_trial?: string;
  price_id_gift?: string;
  country_code: string;
  currency: {
    code: string;
    symbol: string;
    name: string;
  };
};
