import axios from "axios";
import { GATSBY_BE_URL } from "../util/variables";

export const sendSlackErrorMessage = async (message: string) => {
  try {
    await axios.post(`${GATSBY_BE_URL}/sendSlackErrorMessage`, {
      text: message,
    });
  } catch (error) {
    console.error(error);
  }
};
