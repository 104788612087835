const PRIMARY_COLOR = "#20614C";
const BLACK_COLOR = "#10130C";
const GREY_COLOR = "#EEF2F6";
const LIGHT_GREY = "#E0E0E0";
const WHITE_COLOR = "#fefefe";
const WHITE_COLOR_2 = "#FAFAFA";
// const PRIMARY_FONT = "Montserrat";
const PRIMARY_FONT = "boogy brut";
const SECONDARY_FONT = "theinhardt";
const PLUM_COLOR = "#504e64";
const SLATE_COLOR = "#524d57";
const SLATE_COLOR_2 = "#8D8794";
const SLATE_COLOR_3 = "#716B78";
const GREEN_ACCENT = "#68e5c7";
const DARK_GREEN_ACCENT = "#28BDA4";
const RED = "#FF6145";
const ORANGE = "#EC8F58";
const YELLOW = "#FFBF00";
const MINT = "#CBE7E4";
const PURPLE = "#C180F2";
const LIME = "#CDDC99";

// Input Colors
const HOVER_COLOR = "#C1EBDE";
const ACTIVE_COLOR = "#a2edd6";
const SELECTED_COLOR = "#E6F6F1";
const SELECTED_BORDER_COLOR = "#CFDDD9";
const FOCUS_OUTLINE_COLOR = "#CFDDD9";
// const BUTTON_GRADIENT = "linear-gradient(90deg, rgba(115, 123, 253, 0.75) 1.68%, rgba(50, 123, 210, 0.75) 116.81%), #1E85E2";
const theme = {
  PRIMARY_COLOR,
  BLACK_COLOR,
  GREY_COLOR,
  PRIMARY_FONT,
  SECONDARY_FONT,
  WHITE_COLOR,
  WHITE_COLOR_2,
  PLUM_COLOR,
  SLATE_COLOR,
  SLATE_COLOR_2,
  SLATE_COLOR_3,
  GREEN_ACCENT,
  DARK_GREEN_ACCENT,
  RED,
  ORANGE,
  YELLOW,
  LIGHT_GREY,
  SELECTED_COLOR,
  SELECTED_BORDER_COLOR,
  FOCUS_OUTLINE_COLOR,
  HOVER_COLOR,
  ACTIVE_COLOR,
  MINT,
  PURPLE,
  LIME,
};

export default theme;
