import { StringLike } from "@firebase/util";
import { NODE_ENV } from "./variables";

export type SubscriptionType = "yearly" | "monthly" | "coupon";

export const allowedAttributionKeys = new Set([
  "_fbp",
  "_fbc",
  "utm_source",
  "utm_medium",
  "utm_channel",
  "utm_campaign",
  "campaign_id",
  "adset_id",
  "ad_id",
  "utm_term",
]);

/**
 * Checks to see if file exists. Returns a truthy value if it does. A falsy value otherwise.
 * @param path path of file
 */
export const tryRequire = (path: string) => {
  try {
    const temp = require(`${path}`);
    console.log(`image ${path} found.`);
    return temp;
  } catch (err) {
    console.log(`image ${path} not found.`);
    return null;
  }
};

export const getCookie = (cookiename: StringLike) => {
  // Get name followed by anything except a semicolon
  let cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
};

export const getCookies = () => {
  var pairs = document.cookie.split(";");
  var cookies: any = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    const key = (pair[0] + "").trim();
    cookies[key] = unescape(pair.slice(1).join("="));
  }
  return cookies;
};

export const delay = async (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export const storeUTMCookies = search => {
  const urlParams = new URLSearchParams(search);
  // new cookie will be expired in 1 year
  const utm_date = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

  for (const key of urlParams.keys()) {
    // set those parameter as cookies
    const value = urlParams.get(key);
    document.cookie = `${key}=` + value + "; expires=" + utm_date.toUTCString();
  }
};

export const storeUTMDataToLocalStorage = () => {
  const data: any = {};
  const allCookies = getCookies();

  for (const key in allCookies) {
    if (allowedAttributionKeys.has(key)) {
      const value = allCookies[key];
      data[key] = value || "";
    }
  }

  localStorage.setItem("utmData", JSON.stringify(data));
};

export const capitalizeFirstLetter = (text: string) => {
  return text[0].toUpperCase() + text.substring(1);
};

export function preloadImage(img_url: string) {
  const img = new Image();
  img.src = img_url;
}

export const getWebFlow = () => {
  const flow = localStorage.getItem("flow");
  if (flow) {
    return flow;
  }

  return "yearly";
};

export const isDevEnvironment = () => {
  const devStorage = localStorage.getItem("mixpanelDevUser");
  if (devStorage === "true") {
    return true;
  }

  if (NODE_ENV === "development") {
    localStorage.setItem("mixpanelDevUser", "true");
    console.log("Development mode enabled");
    return true;
  }

  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    const dev = params.get("dev");
    if (dev === "true") {
      localStorage.setItem("mixpanelDevUser", "true");
      console.log("Development mode enabled");
      return true;
    }
  }

  return false;
};

export const isPreviewEnvironment = () => window.location.origin.includes("deploy-preview");

export const getStorageURL = (fileName: string) => {
  return `https://firebasestorage.googleapis.com/v0/b/know-d8bc0.appspot.com/o/${fileName}?alt=media&token=50671d8c-f77b-47bd-af89-c90dbf12acbf`;
};

export type Browser = "iOS" | "Android" | "Safari" | "Firefox" | "Chrome" | "Unknown";

export const detectBrowser = (): Browser => {
  const userAgent = navigator.userAgent.toLowerCase();
  console.log("userAgent", userAgent);
  // Check for iOS
  if (/iphone|ipad|ipod/.test(userAgent)) {
    return "iOS";
  }

  // Check for Android
  // eslint-disable-next-line
  if (/android/.test(userAgent)) {
    return "Android";
  }

  // Check for Safari on macOS
  // eslint-disable-next-line
  if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
    return "Safari";
  }

  // Check for Firefox
  // eslint-disable-next-line
  if (/firefox/.test(userAgent)) {
    return "Firefox";
  }

  // Check for Chrome
  // eslint-disable-next-line
  if (/chrome/.test(userAgent) && !/edge|opr/.test(userAgent)) {
    return "Chrome";
  }

  // If none of the above, return 'Unknown'
  return "Unknown";
};
